import "./NoticeBoard.css";
import React, { useEffect, useState } from "react";
import LoadingSpinning from "../loadingSpinning";
import PageNumberBar from "../PageNumberBar/PageNumberBar";

const NoticeBoard = ({
  data,
  note,
  onClick,
  setContent,
  countAdsForPage,
  resetPageNumber,
}) => {
  const listColorsPin = ["red", "green", "blue", "yellow", "orange", "purple"];
  const listColorsBackground = [
    "#CBFAFA",
    "#dcfacb",
    "#fae3cb",
    "#facbcb",
    "#f9cbfa",
  ];

  const [colorsPin, setColorsPin] = useState([]);
  const [colorsBackground, setColorsBackground] = useState([]);
  const [startIndex, setStartIndex] = useState();
  const [endIndex, setEndIndex] = useState();
  const executeScrollTop = () => window.scrollTo(0, 0);

  useEffect(() => {
    setStartIndex(0);
    setEndIndex(countAdsForPage);
  }, [resetPageNumber]);

  useEffect(() => {
    const newListColorsPin = [];
    for (let i = 0; i < countAdsForPage; i++) {
      const ColorPinRandom =
        listColorsPin[Math.floor(Math.random() * listColorsPin.length)];
      newListColorsPin.push(ColorPinRandom);
    }
    setColorsPin(newListColorsPin);

    const newListColorsBackground = [];
    for (let i = 0; i < countAdsForPage; i++) {
      const ColorsBackgroundRandom =
        listColorsBackground[
          Math.floor(Math.random() * listColorsBackground.length)
        ];
      newListColorsBackground.push(ColorsBackgroundRandom);
    }
    setColorsBackground(newListColorsBackground);
  }, []);

  return (
    <div id="frame">
      {note.length === 0 ? (
        <div style={{ marginTop: "20%" }}>
          <LoadingSpinning />
        </div>
      ) : (
        <div>
          <div className="div_all_note">
            {note.slice(startIndex, endIndex).map((item, index) => (
              <div
                key={index}
                className="note sticky"
                style={{
                  backgroundColor: colorsBackground[index],
                  // transform: `rotate(${Math.floor(Math.random() * 10) - 5}deg)`,
                }}
                onClick={() => {
                  data?.length > 0 && onClick && onClick();
                  data?.length > 0 &&
                    setContent &&
                    setContent(data[(startIndex ? startIndex : 0) + index]);
                }}
              >
                <div
                  className="pin"
                  style={{
                    backgroundImage: `radial-gradient(${colorsPin[index]} 40%, black 100%)`,
                  }}
                ></div>
                {item}
              </div>
            ))}
          </div>
        </div>
      )}
      {note?.length > countAdsForPage && (
        <PageNumberBar
          data={note}
          countAdsForPage={countAdsForPage}
          startIndex={startIndex}
          endIndex={endIndex}
          setStartIndex={setStartIndex}
          setEndIndex={setEndIndex}
          executeScrollTop={executeScrollTop}
        />
      )}
    </div>
  );
};
export default NoticeBoard;
