import "./apartment.css";
import { Link } from "react-router-dom";
import FavoriteIcon from "../addToFavorite/addToFavorite";
import SliderImage from "../../components/sliderImage";
import RentTime from "../rentTime/rentTime";
import Logo from "../Logo/Logo";
import { useEffect, useState } from "react";

function Apartment({ listApartments }) {
  // const [showAd, setShowAd] = useState(false);

  // useEffect(() => {
  //   // בדיקה האם יש פרסומת פעילה בזמן טעינת הדף
  //   const adElement = document.querySelector(".adsbygoogle");
  //   setShowAd(adElement && adElement.offsetHeight > 0);

  //   // הוסיפו את האירוע של שינוי גודל החלון אם רוצים לבדוק שוב אחרי שינויים בחלון
  //   // window.addEventListener("resize", handleAdStatus);

  //   // בסיום ההפעלה, נמחק את האירוע לשינוי גודל החלון (אם השתמשנו בו)
  //   // return () => {
  //   //   window.removeEventListener("resize", handleAdStatus);
  //   // };
  // }, []);

  return listApartments?.map((item) =>
    item._id ? (
      <div className="boxApartmentHome" key={item._id}>
        <div className="carousel_images_apartment">
          {item?.images?.length > 0 ? (
            <SliderImage
              listImages={item}
              isAutoPlaying={false}
              hasSizeButton={false}
              hasThumbnails={false}
            />
          ) : (
            <Logo />
          )}
        </div>
        <div className="divFavoriteIcon">
          <FavoriteIcon apartmentForFavorite={item} />
        </div>
        <Link
          className="link"
          to={"/apartmentShow/" + item._id}
          target="_blank"
        >
          <div style={{ height: "100%" }}>
            <div className="nameApartment">{item.nameApartment}</div>

            <div className="location">
              {item.city}, {item.area}.
            </div>

            <div className="beds"> עד {item.beds} מיטות</div>

            <div className="div_time_rent">
              <RentTime rentTime={item.times} />
            </div>

            <div className="phone">
              {item.name} - {item.phone}
            </div>

            <div className="price">
              החל מ- {item.price} ש"ח ל{item.priceMethod}
            </div>
          </div>
        </Link>
      </div>
    ) : (
      // showAd && (
      <div className="box_ads_home_page" key={item.id}>
        {item}
      </div>
      // )
    )
  );
}

export default Apartment;
