import React, { useState, useEffect } from "react";
import "./App.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from "./pages/home/home";
import BarHeader from "./components/BarHeader/BarHeader";
import ApartmentShow from "./pages/apartmentShow/apartmentShow";
import MyFavoritePage from "./pages/myFavorite-page/myFavorite-page";
import PersonalPage from "./pages/personalPage/personalPage";
import Login from "./pages/login/login";
import Manager from "./pages/manager/manager";
import RegisterUser from "./pages/registerUser/registerUser";
import { ContextProvider } from "./variable-Context";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import MaintenanceScreen from "./components/MaintenanceScreen/MaintenanceScreen";
import ReplacementSearch from "./pages/ReplacementSearch/ReplacementSearch";
import BarNavigation from "./components/BarNavigation/BarNavigation";
import Footer from "./components/Footer/Footer";
import Firebase from "./Firebase";
import InitialLoading from "./InitialLoading";
import ContactUs from "./pages/ContactUs/ContactUs";
import PrivacyPolicy from "./components/PrivacyPolicy/privacyPolicy";
import LandingPage from "./pages/landingPage/landingPage";
// import GoogleAds from "./components/GoogleAds";

function App() {
  const topFunction = () => {
    window.scrollTo(0, 0);
  };

  // const GoogleAdsBottom = () => {
  //   return (
  //     <div className="google_ads_bottom">
  //       <GoogleAds
  //         dataAdSlot={"3190845249"}
  //         style={{
  //           display: "inline-block",
  //           width: "360px",
  //           height: "70px",
  //         }}
  //       />
  //     </div>
  //   );
  // };

  return (
    <ContextProvider
      children={
        <>
          <Firebase />
          <InitialLoading />
          <Router>
            <Route
              render={({ location }) => {
                if (location.pathname === "/landingPage") {
                  return (
                    <div>
                      <Route exact path="/landingPage">
                        <LandingPage />;
                      </Route>
                    </div>
                  );
                } else {
                  return (
                    <div className="App" dir="rtl">
                      {/* <div className="google_ads_bottom">
                        <GoogleAds
                          dataAdSlot={"3190845249"}
                          style={{
                            display: "inline-block",
                            width: "360px",
                            height: "70px",
                          }}
                        />
                      </div> */}
                      <div className="top">
                        <DoubleArrowIcon
                          onClick={topFunction}
                          style={{ fontSize: "3rem" }}
                        />
                      </div>
                      <BarHeader />
                      <div className="container">
                        <Switch>
                          <Route exact path="/">
                            <BarNavigation position={"sticky"} />
                            <Home />
                          </Route>

                          <Route exact path="/searchApartment">
                            <BarNavigation position={"sticky"} />
                            <ReplacementSearch
                              url={`${process.env.REACT_APP_SERVER_URL}/api/listPostSearch`}
                            />{" "}
                          </Route>

                          <Route exact path="/BigPlaces">
                            <BarNavigation position={"sticky"} />
                            <MaintenanceScreen
                              content={
                                <div>
                                  העמוד בבנייה
                                  <br />
                                </div>
                              }
                            />{" "}
                          </Route>

                          <Route exact path="/apartmentShow/:id">
                            <BarNavigation position={"sticky"} />
                            <ApartmentShow />
                          </Route>

                          <Route exact path="/login/">
                            <Login />
                          </Route>

                          <Route exact path="/register/">
                            <RegisterUser />
                          </Route>

                          <Route exact path="/user/:id">
                            <PersonalPage />
                          </Route>

                          <Route exact path="/myfavorite/">
                            <BarNavigation position={"sticky"} />
                            <MyFavoritePage />
                          </Route>

                          <Route exact path="/ContactUs/">
                            <BarNavigation position={"sticky"} />
                            <ContactUs />
                          </Route>

                          <Route exact path="/privacyPolicy/">
                            <BarNavigation position={"sticky"} />
                            <PrivacyPolicy />
                          </Route>

                          <Route
                            exact
                            path={`/manager/${process.env.REACT_APP_URL_MANAGER}`}
                          >
                            <Manager />
                          </Route>

                          <Route path="*">
                            <MaintenanceScreen
                              content={<div>העמוד אינו קיים</div>}
                            />{" "}
                          </Route>
                        </Switch>
                      </div>
                      <Footer />{" "}
                    </div>
                  );
                }
              }}
            />
          </Router>
        </>
      }
    />
  );
}

export default App;
