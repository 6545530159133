import React, { useEffect, useContext } from "react";
import { PostToServer, PutToServer } from "./components/getData";
import { initializeApp } from "firebase/app";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { AppContext } from "./variable-Context";

const Firebase = () => {
  const { setUidFirebase, setDetailsUsers } = useContext(AppContext);

  const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
  };

  initializeApp(firebaseConfig);

  const auth = getAuth();
  // console.log("auth -", auth);

  useEffect(() => {
    onAuthStateChanged(auth, (userForFirebase) => {
      if (userForFirebase) {
        setUidFirebase(userForFirebase.uid);
        PostToServer(
          `${process.env.REACT_APP_SERVER_URL}/api/userConnected/${userForFirebase.uid}`,
          null,
          setDetailsUsers
        );
      }
    });
  }, [auth]);

  return null;
};

export default Firebase;
